<template>
  <div class="h5-news-wrap">
    <h3>信息完善</h3>
    <p class="news-tip">企业员工认证成功！请完善以下信息</p>
    <van-form class="news-form" ref="newsForm" @submit="onAchieve">
      <van-field
        v-if="authInfoObj.registerInfo.email"
        class="code-wrap"
        v-model.trim="newsForm.email"
        placeholder="请输入邮箱"
        :rules="[{ required: authInfoObj.registerInfo.emailRequired, message: '请输入邮箱' }, { validator: emailValidator, message: '请输入正确的邮箱', trigger: 'onBlur'}]"
        clearable />
      <van-field
        v-if="authInfoObj.registerInfo.workNo"
        v-model.trim="newsForm.workNo"
        placeholder="请输入工号"
        :rules="[{ required: authInfoObj.registerInfo.workNoRequired, message: '请输入工号' }]"
        clearable />
      <van-field
        v-if="authInfoObj.registerInfo.orgNo"
        class="code-wrap"
        placeholder="请选择部门"
        :rules="[{ required: authInfoObj.registerInfo.orgNoRequired, message: '请选择部门', trigger: 'onBlur' }]"
        clearable
        readonly
        :value="newsForm.orgName"
        @click="orgIdObj.isShow = true" />
      <van-button class="news-btn" type="primary" round :loading="isBtnLoading" native-type="submit">完成</van-button>
    </van-form>
    <a href="javascript:;" class="no-news" @click="onJump">跳过，暂不填写</a>
    <popup-tree-select v-if="orgIdObj.isShow" :dialogData="orgIdObj" :disabledRoot="isDisabledRoot" v-model="newsForm.orgId" @success="handlerTree" />
    <popup-share-menus></popup-share-menus>  
  </div>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Form, Field, Button, Toast } from 'vant'
  import { local } from '@/utils'
  import popupTreeSelect from '@/components/h5/treeSelect'
  import popupShareMenus from '@/components/h5/shareMenus'

  export default {
    components: {
      [Form.name]: Form,
      [Field.name]: Field,
      [Button.name]: Button,
      popupTreeSelect,
      popupShareMenus
    },
    data() {
      return {
        isBtnLoading: false,
        newsForm: {
          email: '',
          workNo: '',
          orgId: '',
          orgName: ''
        },
        isDisabledRoot: true,
        orgIdObj: { isShow: false },
        authInfoObj: {
          registerInfo: {}
        }
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
      jobId() {
        return this.$route.query.jobId || '';
      },
      pageFrom() {
        return this.$route.query.pageFrom || '';
      }
    },
    created() {
      this.queryConfig();
    },
    methods: {
      queryConfig() {
        this.$http.get('/api/internal/recom/auth/info.json', {params: {
          websiteId: this.uccnInfo.id
        }}).then(data => {
          if(data) {
            this.authInfoObj = data;
          }
        })
      },
      emailValidator(val) {
        return !val || /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(val);
      },
      handlerTree({ name }) {
        this.newsForm.orgName = name;
      },
      onAchieve() {
        this.$refs.newsForm.validate().then(() => {
          this.isBtnLoading = true;
          this.$http.get('/api/internal/recom/person/info/save.json', {params: {
            workNo: this.newsForm.workNo,
            email: this.newsForm.email,
            orgId: this.newsForm.orgId,
            websiteId: this.uccnInfo.id
          }}).then(() => {
            Toast.success({
              message: '操作成功',
              duration: 3000
            });
            this.onJump();
          }).catch(err => {
            console.log(err);
          }).finally(() => {
            this.isBtnLoading = false;
          });
        });
      },
      onJump() {
        if(this.jobId) {
          this.$router.push({
            name: 'jobDetail',
            query: { websiteId: this.uccnInfo.id },
            params: { id: this.jobId }
          });
        } else if(this.pageFrom) {
          this.$router.push({
            name: this.pageFrom,
            query:  { websiteId: this.uccnInfo.id }
          });
        } else {
          this.$router.push({
            name: 'Home',
            query: { id: this.uccnInfo.id },
            params: { pathMatch: local.get('rootPath') || '' }
          });
        }
      }
    }
  }
</script>
