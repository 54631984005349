<template>
  <li :class="'layer-item' + layer">
    <div class="text">
      <span class="el-sl" :class="{'active': activeId === item.id}" @click="onSelect(item)">{{ item.name }}</span>
      <i v-if="hasChildren" @click="toggleExpand(item)" :class="{ 'active': isOpen }" class="h5pp h5pp-icon-arrow-down-entity"></i>
    </div>
    <ul v-if="hasChildren" v-show="isOpen">
      <sub-tree-item 
        v-for="(child, index) in item.children"
        :layer="layer + 1"
        :key="index"
        :item="child"
        :activeId="activeId"
        @select="onSelect"
      />
    </ul>
  </li>
</template>
<script>
  import './index.scss'

  export default {
    name: 'sub-tree-item',
    props: {
      item: Object,
      layer: Number,
      activeId: [String, Number],
      disabledRoot: Boolean
    },
    data() {
      return {
        isOpen: true
      };
    },
    computed: {
      hasChildren() {
        return this.item.children && this.item.children.length;
      }
    },
    methods: {
      toggleExpand() {
        if(this.hasChildren) {
          this.isOpen = !this.isOpen;
        }
      },
      onSelect(item) {
        if(this.disabledRoot) {
          if(item.id) {
            this.$emit('select', item);
          }
        } else {
          this.$emit('select', item);
        }
      }
    }
  }
</script>
