<template>
  <div class="certified-email-wrap">
    <i class="h5pp" :class="showEmailPage === 1 ? 'h5pp-icon-done' : 'h5pp-icon-faile'"></i>
    <span>{{ showEmailPage === 1 ? '您已完成邮箱认证，可关闭此页面，继续浏览内推官网' : '邮箱认证不通过，可返回上一步重新发送认证邮件' }}</span>
  </div>
</template>

<script>
  import './index.scss'
  
  export default {
    computed: {
      showEmailPage() {
        return +this.$route.query.showEmailPage || false;
      }
    }
  }
</script>
