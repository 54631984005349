<template>
  <div>
    <sub-h5-email v-if="$utils.isPhone()"></sub-h5-email>
    <sub-pc-email v-else></sub-pc-email>
  </div>
</template>
<script>
  import subH5Email from '@/views/submodule/h5/email'
  import subPcEmail from '@/views/submodule/pc/email'

  export default {
    components: {
      subH5Email,
      subPcEmail
    }
  }
</script>
