<template>
  <div class="h5-emplyoee-wrap">
    <h3>企业员工</h3>
    <van-form class="employee-form" validate-trigger="onSubmit" ref="employeeForm" @submit="onRegistory">
      <van-field
        v-model.trim="employeeForm.name"
        placeholder="请输入姓名"
        :maxlength="20"
        clearable
        :rules="[{ required: true, message: '请输入姓名', trigger: 'onBlur'}]" />
      <van-field
        v-if="authInfoObj.mobileSwitchFlag"
        class="code-wrap"
        v-model.trim="employeeForm.authCode"
        placeholder="员工邀请码"
        clearable
        :rules="[{ required: authInfoObj.mobileSwitchFlag, message: '请输入员工邀请码' }]" />
      <van-field
        v-if="authInfoObj.emailSwitchFlag"
        class="code-wrap"
        v-model.trim="employeeForm.email"
        placeholder="企业邮箱"
        :rules="[{ required: authInfoObj.emailSwitchFlag, message: '请输入企业邮箱' },
        { validator: emailValidator, message: '请输入正确的邮箱', trigger: 'onBlur'},
        { validator: emailValidatorDomain, message: '此邮箱不为企业内部邮箱', trigger: 'onBlur'}]"
        clearable />
      <van-button class="employee-btn" type="primary" round :loading="isBtnLoading" native-type="submit">身份认证</van-button>
    </van-form>
    <a href="javascript:;" class="no-employee" @click="$router.push({ name: 'identityOut', query: { jobId, pageFrom }})">我不是企业员工，前往个人认证</a>
    <popup-share-menus></popup-share-menus>  
  </div>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Form, Field, Button, Toast } from 'vant';
  import { local } from '@/utils'
  import popupShareMenus from '@/components/h5/shareMenus'

  export default {
    components: {
      [Form.name]: Form,
      [Field.name]: Field,
      [Button.name]: Button,
      popupShareMenus
    },
    data() {
      return {
        isBtnLoading: false,
        employeeForm: {
          name: '',
          authCode: '',
          email: ''
        },
        authInfoObj: {}
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
      jobId() {
        return this.$route.query.jobId || '';
      },
      pageFrom() {
        return this.$route.query.pageFrom || '';
      }
    },
     created() {
      this.queryConfig();
    },
    methods: {
      queryConfig() {
        this.$http.get('/api/internal/recom/auth/info.json', {params: {
          websiteId: this.uccnInfo.id
        }}).then(data => {
          if(data) {
            this.authInfoObj = data;
          }
        })
      },
      emailValidatorDomain(val) {
        if(this.authInfoObj.domains && this.authInfoObj.domains.length) {
          let domainIndex = val.lastIndexOf('@');
          let domain = val.substring(domainIndex+1, val.length);
          let emailDomains = this.authInfoObj.domains.map(item => item.emailDomain);
          if(!emailDomains.includes(domain)) {
            return false;
          } else {
            return true;
          }
        }
      },
      emailValidator(val) {
        return !val || /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(val);
      },
      onRefresh() {
        this.$http.get('/api/internal/recom/person/flush.json', {params: {
          websiteId: this.uccnInfo.id
        } }).then(data => {
          if(data.personId) {
            this.$store.commit('SET_USER_INFO', data);
            if(data.checkFlag) {
              this.$router.push({ name: 'identityNews', query: { jobId: this.jobId }});
            } else {
              if(this.jobId) {
                this.$router.push({
                  name: 'jobDetail',
                  query: { websiteId: this.uccnInfo.id },
                  params: { id: this.jobId }
                });
              } else if(this.pageFrom) {
                this.$router.push({
                  name: this.pageFrom,
                  query: { websiteId: this.uccnInfo.id }
                });
              } else {
                this.$router.push({
                  name: 'Home',
                  query: { id: this.uccnInfo.id },
                  params: { pathMatch: local.get('rootPath') || '' }
                });
              }
            }
          }
        });
      },
      onRegistory() {
        this.$refs.employeeForm.validate().then(() => {
          this.isBtnLoading = true;
          this.$http.get('/api/internal/recom/person/auth.json', {params: {
            authType: 0, // 0 公司内部认证 1 外部员工认证
            name: this.employeeForm.name,
            authCode: this.employeeForm.authCode,
            email: this.employeeForm.email,
            websiteId: this.uccnInfo.id
          }}).then(() => {
            Toast.success({
              message: '认证成功',
              duration: 3000
            });
            if(this.authInfoObj.emailSwitchFlag) {
              this.$router.push({ name: 'identityEmail', query: { jobId: this.jobId, pageFrom: this.pageFrom }});
            } else {
              this.onRefresh();
            }
          }).finally(() => {
            this.isBtnLoading = false;
          });
        });
      }
    }
  }
</script>
