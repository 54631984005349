<template>
  <div class="h5-out-user-wrap">
    <h3>外部用户</h3>
    <van-form class="out-user-form" ref="outUserForm" @submit="onConfirm">
      <van-field
        v-model.trim="outUserForm.name"
        placeholder="请输入姓名"
        :maxlength="20"
        clearable
        :rules="[{ required: true, message: '请输入姓名', trigger: 'onBlur'}]"
        />
      <p class="out-user-tip">认证后可推荐人选</p>
      <van-button class="out-user-btn" type="primary" round :loading="isBtnLoading" native-type="submit">确认</van-button>
    </van-form>
    <a href="javascript:;" class="no-out-user" @click="$router.push({ name: 'identityEmployee', query: { jobId, pageFrom }})">我是企业员工，前往员工认证</a>
    <popup-share-menus></popup-share-menus>  
  </div>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Form, Field, Button, Toast } from 'vant';
  import { local } from '@/utils'
  import popupShareMenus from '@/components/h5/shareMenus'

  export default {
    components: {
      [Form.name]: Form,
      [Field.name]: Field,
      [Button.name]: Button,
      popupShareMenus
    },
    data() {
      return {
        isBtnLoading: false,
        outUserForm: {
          name: ''
        }
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
      jobId() {
        return this.$route.query.jobId || '';
      },
      pageFrom() {
        return this.$route.query.pageFrom || '';
      }
    },
    methods: {
      onConfirm() {
        this.$refs.outUserForm.validate().then(() => {
          this.isBtnLoading = true;
          this.$http.get('/api/internal/recom/person/auth.json', {params: {
            authType: 1, // 0 公司内部认证 1 外部员工认证
            name: this.outUserForm.name,
            websiteId: this.uccnInfo.id
          }}).then((data) => {
            if(data.personId) {
              this.$store.commit('SET_USER_INFO', data);
              Toast.success({
                message: '认证成功',
                duration: 3000
              });
              if(this.jobId) {
                this.$router.push({
                  name: 'jobDetail',
                  query: { websiteId: this.uccnInfo.id },
                  params: { id: this.jobId }
                });
              } else if(this.pageFrom) {
                this.$router.push({
                  name: this.pageFrom,
                  query:  { websiteId: this.uccnInfo.id }
                });
              } else {
                this.$router.push({
                  name: 'Home',
                  query: { id: this.uccnInfo.id },
                  params: { pathMatch: local.get('rootPath') || '' }
                });
              }
            }
          }).catch(err => {
            console.log(err);
          }).finally(() => {
            this.isBtnLoading = false;
          });
        });
      }
    }
  }
</script>
