<template>
  <div class="h5-identity-wrap">
    <h3>请选择您的身份</h3>
    <div class="company-employee" @click="$router.push({ name: 'identityEmployee', query: { jobId, pageFrom }})">
      <i class="h5pp h5pp-icon-company"></i>
      <dl class="user-box">
        <dt>我是企业员工</dt>
        <dd>EMPLOYEES OF THE ENTERPRISES</dd>
      </dl>
      <i class="h5pp h5pp-icon-arrows-right"></i>
    </div>
    <div v-if="authInfoObj.externalRecomFlag" class="out-user" @click="$router.push({ name: 'identityOut', query: { jobId, pageFrom }})">
      <i class="h5pp h5pp-icon-user"></i>
      <dl class="user-box">
        <dt>我是外部用户</dt>
        <dd>EXTERNAL USER</dd>
      </dl>
      <i class="h5pp h5pp-icon-arrows-right"></i>
    </div>
    <a href="javascript:;" class="no-identity" @click="onUnRegistory">随便看看，暂不认证</a>
    <popup-share-menus />
  </div>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Form, Field, Button } from 'vant';
  import { local } from '@/utils'
  import popupShareMenus from '@/components/h5/shareMenus'

  export default {
    components: {
      [Form.name]: Form,
      [Field.name]: Field,
      [Button.name]: Button,
      popupShareMenus
    },
    data() {
      return {
        authInfoObj: {}
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
      jobId() {
        return this.$route.query.jobId || '';
      },
      pageFrom() {
        return this.$route.query.pageFrom || '';
      }
    },
    created() {
      this.queryConfig();
    },
    methods: {
      onUnRegistory() {
        if(this.jobId) {
          this.$router.push({
            name: 'jobDetail',
            query: { websiteId: this.uccnInfo.id },
            params: { id: this.jobId }
          });
        } else if(this.pageFrom) {
          this.$router.push({
            name: this.pageFrom,
            query: { websiteId: this.uccnInfo.id }
          });
        } else {
          this.$router.push({
            name: 'Home',
            query: { id: this.uccnInfo.id },
            params: { pathMatch: local.get('rootPath') || '' }
          });
        }
      },
      queryConfig() {
        this.$http.get('/api/internal/recom/auth/info.json', {params: {
          websiteId: this.uccnInfo.id
        }}).then(data => {
          if(data) {
            this.authInfoObj = data;
          }
        })
      }
    }
  }
</script>
