<template>
  <sub-h5-identity v-if="$utils.isPhone()"></sub-h5-identity>
</template>

<script>
  import subH5Identity from '@/views/submodule/h5/identity'

  export default {
    components: {
      subH5Identity
    }
  }
</script>
