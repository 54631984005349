<template>
  <van-popup v-model="dialogData.isShow" round position="bottom" class="tree-select-wrap">
    <div class="popup-wrap">
      <a href="javascript:;" class="popup-cancel" @click="dialogData.isShow = false">取消</a>
      <div class="van-ellipsis popup-title"></div>
      <a href="javascript:;" class="popup-confirm" @click="onConfirm">确认</a>
    </div>
    <ul class="tree-list">
      <sub-tree-item 
        v-for="(child, index) in deptData"
        :key="index"
        :item="child"
        :layer="1"
        :activeId="selectItem.id"
        :disabledRoot="disabledRoot"
        @select="onSelect"
      />
    </ul>
  </van-popup>
</template>

<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Popup } from 'vant'
  import subTreeItem from '@/components/h5/treeSelect/submodule/treeItem'

  export default {
    components: {
      [Popup.name]: Popup,
      subTreeItem
    },
    model: {
      prop: 'orgId',
      event: 'change'
    },
    props: {
      dialogData: Object,
      orgId: [Number, String],
      disabledRoot: {
        type: Boolean,
        default: false,
      } 
    },
    data() {
      return {
        selectItem: {
          id: ''
        },
        deptData: []
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
      defaulIndex() {
        return this.dialogData.index ? this.dialogData.index : 0;
      },
      defaultKey() {
        return this.dialogData.key ? this.dialogData.key : 'text';
      }
    },
    created() {
      if(this.dialogData.columns) {
        this.deptData = this.dialogData.columns;
        this.selectItem.id = this.dialogData.id;
      } else {
        this.getOrgData();
        this.selectItem.id = this.orgId;
      }
    },
    methods: {
      //获取组织数据
      getOrgData() {
        this.$http.get('/api/company/org/list.json', {params: {
          websiteId: this.uccnInfo.id
        }}).then(data => {
          if(data && data.length) {
            this.deptData = data;
          }
        });
      },
      onSelect(item) {
        this.selectItem = item;
      },
      onConfirm() {
        if(this.selectItem.id && this.selectItem.name){
          this.$emit('change', this.selectItem.id);
          this.$emit('success', this.selectItem);
        }
        this.dialogData.isShow = false;
      }
    }
  }
</script>