<template>
  <div class="h5-identity-email">
    <img src="@/assets/images/img_sendNews.png">
    <dl class="identity-box">
      <dt>认证邮件已发送至企业邮箱</dt>
      <dd>请点击邮件中的链接进行验证</dd>
    </dl>
    <a v-if="!isLoading" href="javascript:;" @click="onRefresh"><i class="h5pp h5pp-icon-refresh"></i>点击刷新</a>
    <van-loading v-else class="loading" color="#0055DC" />
    <popup-share-menus />
  </div>
</template>
<script>
  import './index.scss'
  import { Loading } from 'vant'
  import { mapGetters } from 'vuex'
  import { local } from '@/utils'
  import popupShareMenus from '@/components/h5/shareMenus'
  
  export default {
    components: {
      [Loading.name]: Loading,
      popupShareMenus
    },
    data() {
      return {
        isLoading: false
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
      jobId() {
        return this.$route.query.jobId || '';
      },
      pageFrom() {
        return this.$route.query.pageFrom || '';
      }
    },
    methods: {
      onRefresh() {
        this.isLoading = true;
        this.$http.get('/api/internal/recom/person/flush.json', {params: {
          websiteId: this.uccnInfo.id
        } }).then(data => {
          if(data.personId) {
            this.$store.commit('SET_USER_INFO', data);
            if(!data.checkFlag) {
              if(this.jobId) {
                this.$router.push({
                  name: 'jobDetail',
                  query: { websiteId: this.uccnInfo.id },
                  params: { id: this.jobId }
                });
              } else if(this.pageFrom) {
                this.$router.push({
                  name: this.pageFrom,
                  query: { websiteId: this.uccnInfo.id }
                });
              } else {
                this.$router.push({
                  name: 'Home',
                  params: { pathMatch: local.get('rootPath') || '' }
                });
              }
            } else {
              this.$router.push({ name: 'identityNews', query: { jobId: this.jobId, pageFrom: this.pageFrom } });
            }
          }
        }).finally(() => {
          this.isLoading = false;
        });
      }
    }
  }
</script>
